import ApiBase from '@core/api';
import get from 'lodash/get';
import { getEnvSaveFeedBack, getEnvSaveTestResult, getEnvWikiTestEndpoint } from '@/env/requestEndpoints';
import { ITestDetail } from '@api/test-api-service/models';
import { Inject } from 'react-ioc';


interface IWithResponse<T = any> {
    status: 'success' | 'error' | 'fail';
    message?: string;
    data: T;
}


class TestApiService {
    @Inject apiBase: ApiBase;

    async getTest(
        testId: number,
        categoryId?: number,
        articleId?: number,
    ): Promise<ITestDetail> {
        const params = {
            test_id: testId,
            ...(categoryId ? { category_id: categoryId } : null),
            ...(articleId ? { article_id: articleId } : null),
        };

        const response = await this.apiBase.get<IWithResponse<ITestDetail>>(getEnvWikiTestEndpoint(), params);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data as ITestDetail;
    }

    async saveResult(
        answerId: number,
        categoryId: number | undefined,
        articleId: number | undefined,
    ): Promise<boolean> {
        const body = {
            test_answer_ids: [answerId],
            category_id: categoryId,
            article_id: articleId,
        };

        const response = await this.apiBase.post<IWithResponse<ITestDetail>>(getEnvSaveTestResult(), body);

        const { message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return true;
    }

    async saveFeedBack(
        articleId: number,
        rating: number,
        feedback: string,
    ): Promise<boolean> {
        const body = {
            article_id: articleId,
            rating,
            feedback,
        };

        const response = await this.apiBase.post<IWithResponse<ITestDetail>>(getEnvSaveFeedBack(), body);

        const { message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return true;
    }
}


export default TestApiService;
