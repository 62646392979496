import React, { FunctionComponent } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { Locations } from '@core/models/locations';
import Login from '@components/login/Login';
import TrainingRoomPage from '@components/main/training-room-page';
import Categories from '@components/main/training-room-page/categories/categories';
import CategoryView from '@components/main/training-room-page/category-view/category-view';
import Main from '@components/main/Main';
import ArticleView from '@components/main/training-room-page/article-view/article-view';
import ArticleTest from '@components/main/training-room-page/article-test/article-test';


const MainRoutes: FunctionComponent = () => {
    return useRoutes([
        { path: Locations.LOGIN, element: <Login /> },
        { path: Locations.MAIN, element: <Main />, children: [
            {
                path: Locations.TRAINING_ROOM, element: <TrainingRoomPage />, children: [
                    { path: Locations.CATEGORIES, element: <Categories /> },
                    { path: `${Locations.CATEGORY_VIEW}/:id`, element: <CategoryView /> },
                    { path: `${Locations.ARTICLE_VIEW}/:id`, element: <ArticleView /> },
                    { path: `${Locations.ARTICLE_TEST}/:id`, element: <ArticleTest /> },
                    { path: Locations.TRAINING_ROOM, element: <Navigate replace to={Locations.CATEGORIES} /> },
                ],
            },

        ] },
    ]);
};


export default MainRoutes;
