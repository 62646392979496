import { IAvailableWikiArticleModel } from '@models/mobx-state-tree/training-room/availableWikiArticle.model';
import { block } from 'bem-cn';
import React, { FunctionComponent } from 'react';
import ArrowRightSvg from '../../sidebar/includes/icons/ArrowRightSvg';

import './article-card.scss';

interface IArticleCardProps {
    articleInfo: IAvailableWikiArticleModel;
    onClick: () => void;
}

const ArticleCard: FunctionComponent<IArticleCardProps> = ({
    articleInfo: {
        title,
        isOpenByProgress,
        description,
    },
    onClick,
}) => {
    const b = block('article-card');
    return (
        <div onClick={onClick} className={b(
            { disabled: !isOpenByProgress },
        )}
        >
            <div>
                <ArrowRightSvg className='article-card-arrow'/>
            </div>
            {/* <Tag
                className={state.name}
                text={state.name}
            /> */}
            <div className={b('card-title')}>
                {title}
            </div>
            <div className={b('card-description')}>
                {description}
            </div>
        </div>
    );
};

export default ArticleCard;
