import React, { FunctionComponent } from 'react';

import CheckMarkSvg from '@components/resources/SVG/CheckMark/CheckMarkSvg';
import Button from '@UIElements/Button';
import { IModalFunctionComponentPropsBase } from '@core/models/ModalWindow';
import { observer } from 'mobx-react';

import './feeedback-sent.scss';


interface IFeedbackSentProps extends IModalFunctionComponentPropsBase {
    textOfButton: string;
}

const FeedbackSent: FunctionComponent<IFeedbackSentProps> = ({
    t,
    success,
    textOfButton,
}) => {

    return (
        <div className = 'feedback-sent'>
            <CheckMarkSvg />
            <div className = 'feedback-sent__title'>
                {t('Отзыв отправлен!', 'Feedback sent!')}
            </div>
            <div className= 'feedback-sent__text'>
                {t(
                    'Спасибо за помощь в улучшении качества обучения. Мы обязательно учтем ваш отзыв в будущем',
                    'Thank you for helping to improve the quality of learning. We will definitely consider your feedback in the future',
                )}
            </div>
            <Button
                onClick={success}
                variant = "7"
                size = "1"
                text = {textOfButton}
                fontFamily = 'Roboto'
                padding='16px'
            />
        </div>
    );
};


export default observer(FeedbackSent);
