import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import { block } from 'bem-cn';

import Button from '@UIElements/Button';
import FeedBackStarSvg from '@components/resources/SVG/FeedBackStar/FeedBackStarSvg';
import { IModalFunctionComponentPropsBase } from '@core/models/ModalWindow';
import TestApiService from '@api/test-api-service';

import './feedback-choice.scss';

interface IFeedbackChoiceProps extends IModalFunctionComponentPropsBase {
    testApiService: TestApiService;
    articleId: number;
}

const FeedbackChoice: FunctionComponent<IFeedbackChoiceProps> = ({
    t,
    success,
    testApiService,
    articleId,
    cancel,
}) => {
    const [value, setValue] = useState<string>('');
    const [star, setStar] = useState<number>(-1);
    const [hoverStar, setHoverStar] = useState<number>(-1);

    const b = block('feedback-choice');

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value);

    const onClick = (starNumber: number) => {
        if (starNumber === star) {
            setHoverStar(starNumber - 1);
            return setStar(starNumber - 1);
        }
        setStar(starNumber);
    };

    const sendFeedBack = async () => {
        try {
            const isSuccess = await testApiService.saveFeedBack(articleId, star + 1, value);

            if (isSuccess) {
                success();
            }
        } catch (error) {
            cancel(error.message);
        }
    };

    return (
        <div className={b()}>
            <div className={b('title')}>
                {t('Урок успешно пройден!', 'Lesson successfully passed!')}
            </div>
            <div className={b('text')}>
                {t('Оцените, пожалуйста, качество урока', 'Please evaluate the quality of the lesson:')}
            </div>
            <div className={b('stars')}>
                {Array.from({ length: 5 }).map((el, index) =>
                    <div
                        key={`star${index}`}
                        onClick={() => onClick(index)}
                        onMouseEnter={() => setHoverStar(index)}
                        onMouseLeave={() => setHoverStar(-1)}
                    >
                        <FeedBackStarSvg
                            color = {star !== -1 && index <= star ||
                            hoverStar !== -1 && index <= hoverStar ? 'blue' : 'empty'}
                        />
                    </div>,
                )}
            </div>
            <textarea
                placeholder={t('Напишите ваши предложения', 'Write your suggestions')}
                className={b('textarea')}
                value={value}
                onChange={onChange}
            >
            </textarea>
            <div className={b('buttons')}>
                <div className={b('button-next-lesson')}>
                    <Button
                        variant='7'
                        size='1'
                        text={t('отправить отзыв', 'send feedback')}
                        fontFamily='Roboto'
                        onClick={sendFeedBack}
                        disabled={star === -1}
                        padding='16px'
                    />
                    {/*<Button*/}
                    {/*    variant='2'*/}
                    {/*    size='1'*/}
                    {/*    text={t('следующий урок', 'next lesson')}*/}
                    {/*    fontFamily='Roboto'*/}
                    {/*/>*/}
                </div>
            </div>
        </div>
    );
};

export default observer(FeedbackChoice);
