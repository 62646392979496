import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';
import { AnswerModel } from '@models/mobx-state-tree/training-room/answer.model';


export const WikiTestModel = t
    .model('WikiTestModel', {
        id: t.identifier,
        testId: t.integer,
        question: t.string,
        ordinalNumber: t.integer,
        answers: t.array(AnswerModel),
    });


export interface IWikiTestModelModel extends Instance<typeof WikiTestModel> {}
export interface IWikiTestModelSnapshotIn extends SnapshotIn<typeof WikiTestModel> {}
export interface IWikiTestModelSnapshotOut extends SnapshotOut<typeof WikiTestModel> {}
