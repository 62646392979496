import React, { FunctionComponent } from 'react';

import './RadioInput.scss';

interface IRadioInput {
    value: any;
    label?: number;
    param?: any;
}

const RadioInput: FunctionComponent<IRadioInput> = ({ value, label, param }) => {
    return (
        <div className='radio'>
            <input
                className='radio__input'
                type='radio'
                value={value}
                id={String(value)}
                onChange={() => {}}
                checked={value === param}
            />
            <label
                className='radio__label'
                htmlFor={String(value)}
            >
                {label}
            </label>
        </div>
    );
};

export default RadioInput;

