import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';
import { StateStatusModel } from '@models/mobx-state-tree/training-room/stateStatus.model';


export const AvailableWikiBaseModel = t
    .model('AvailableWikiBaseModel', {
        id: t.identifier,
        name: t.string,
        title: t.string,
        isOpenByProgress: t.boolean,
        state: StateStatusModel,
    });


export interface IAvailableWikiBaseModel extends Instance<typeof AvailableWikiBaseModel> {}
export interface IAvailableWikiBaseModelSnapshotIn extends SnapshotIn<typeof AvailableWikiBaseModel> {}
export interface IAvailableWikiBaseModelSnapshotOut extends SnapshotOut<typeof AvailableWikiBaseModel> {}
