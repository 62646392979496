import React, { FunctionComponent } from 'react';

interface IFeedBackStarSvg {
    color?: 'empty' | 'blue' | undefined;
}

const FeedBackStarSvg: FunctionComponent<IFeedBackStarSvg> = ({ color }): JSX.Element => {
    const path = color === 'blue' ?
        <path d="M17.9999 28.0393L26.2999 33.0593C27.8199 33.9793 29.6799 32.6193 29.2799 30.8993L27.0799 21.4593L34.4199 15.0993C35.7599 13.9393 35.0399 11.7393 33.2799 11.5993L23.6199 10.7793L19.8399 1.85929C19.1599 0.239287 16.8399 0.239287 16.1599 1.85929L12.3799 10.7593L2.7199 11.5793C0.959901 11.7193 0.239901 13.9193 1.5799 15.0793L8.9199 21.4393L6.7199 30.8793C6.3199 32.5993 8.1799 33.9593 9.6999 33.0393L17.9999 28.0393Z" fill="#0060FF"/>
        :
        <path fillRule="evenodd" clipRule="evenodd" d="M23.617 10.7493L33.297 11.5893C35.057 11.7293 35.757 13.9293 34.417 15.0893L27.077 21.4493L29.277 30.9093C29.677 32.6293 27.817 33.9893 26.297 33.0693L17.997 28.0493L9.69697 33.0493C8.17697 33.9693 6.31697 32.6093 6.71697 30.8893L8.91697 21.4493L1.57697 15.0893C0.236971 13.9293 0.956971 11.7293 2.71697 11.5893L12.377 10.7693L16.157 1.84928C16.837 0.229277 19.157 0.229277 19.837 1.84928L23.617 10.7493ZM10.477 28.8493L17.997 24.3093L25.537 28.8693L23.537 20.3093L30.177 14.5493L21.417 13.7893L17.997 5.70928L14.597 13.7693L5.83697 14.5293L12.477 20.2893L10.477 28.8493Z" fill="#B8C1CC"/>;

    return (
        <svg
            width="36"
            height="34"
            viewBox="0 0 36 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            ${path}
        </svg>
    );
};


export default FeedBackStarSvg;
