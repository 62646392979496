import React, { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import { TrainingRoomStore } from '@store/TrainingRoomStore';

import { block } from 'bem-cn';

import { useNavigate } from 'react-router-dom';
import useQueriesHook from '@components/hooks/useQueriesHook';

import TrainingRoomService from '@components/main/training-room-page/TrainingRoomService';

import ArrowBackGraySvg from '@/app/components/resources/SVG/ArrowBackGraySvg';
import ArticleCard from '../article-card/article-card';
import BottomMobileTabs, { BottomMobileTabsPageEnum } from '../../bottom-mobile-menu/BottomMobileTabs';
import { Locations } from '@/app/core/models/locations';
import I18NService from '@services/I18NService';
import Spinner from '@UIElements/Spinner';

import './category-view.scss';



const CategoryView: FunctionComponent = () => {
    const [
        {
            selectedAvailableWikiCategoryModel,
            clearSelectedAvailableWikiArticleModel,
        },
        trainingRoomService,
        {
            t,
        },
    ] = useInstances(
        TrainingRoomStore,
        TrainingRoomService,
        I18NService,
    );

    const b = block('category-view');

    const navigate = useNavigate();

    const { paramsValues, id } = useQueriesHook(['categoriesScrollY', 'articlesScrollY']);

    const { categoriesScrollY, articlesScrollY } = paramsValues;

    useEffect(
        () => {
            const categoryId = Number(id);
            if (articlesScrollY) window.scrollTo(0, Number(articlesScrollY));
            if (!isNaN(categoryId)) {
                void trainingRoomService.initCategoryModel(categoryId);
            } else {
                console.error('CategoryId not defined');
            }

            return () => {
                clearSelectedAvailableWikiArticleModel();
            };
        },
        [],
    );

    if (!selectedAvailableWikiCategoryModel) {
        return (
            <div className='spinner'>
                <Spinner/>
            </div>
        );
    }

    const {
        title,
        countStudiedArticles,
        countAllArticles,
        articlesView,
    } = selectedAvailableWikiCategoryModel;

    const toLessonView = (articleId: number) => {
        const articlesScrollY = Math.floor(window.scrollY);
        navigate(`${Locations.ARTICLE_VIEW}/${articleId}?categoriesScrollY=${categoriesScrollY}&articlesScrollY=${articlesScrollY}`);
    };

    const backToCategories = () => {
        navigate(`${Locations.CATEGORIES}?categoriesScrollY=${categoriesScrollY}`);
    };

    return (
        <>
            <div className={b()}>
                <div className={b('header')}>
                    <div onClick={backToCategories} className={b('arrow')}>
                        <ArrowBackGraySvg />
                    </div>
                    <div className={b('article-counts')}>{countStudiedArticles} / {countAllArticles}</div>
                </div>

                <>
                    <div className={b('title')}>
                        {title}
                    </div>
                    {
                        articlesView.map(
                            (article) => <ArticleCard
                                articleInfo={article}
                                onClick={() => toLessonView(article.articleId)}
                                key={article.id}
                            />,
                        )
                    }
                </>
                {!articlesView!.length && (
                    <div className="no-content">
                        {t('Нет статей', 'No articles')}
                    </div>
                )}
            </div>
            <BottomMobileTabs page={BottomMobileTabsPageEnum.TRAINING_ROOM} />
        </>
    );
};


export default observer(CategoryView);
