import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import { useNavigate, useParams } from 'react-router-dom';

import { block } from 'bem-cn';

import { TrainingRoomStore } from '@store/TrainingRoomStore';
import TrainingRoomService from '@components/main/training-room-page/TrainingRoomService';
import ModalService from '@core/services/ModalService';
import I18NService from '@services/I18NService';
import Spinner from '@UIElements/Spinner';
import ArrowBackSvg from '@components/resources/SVG/ArrowBack/ArrowBackSvg';
import RadioInput from '@UIElements/RadioInput/RadioInput';
import ProgressBar from '@UIElements/ProgressBar/ProgressBar';
import TrainingRoomFooter from '@components/main/training-room-page/training-room-footer/training-room-footer';
import Button from '@UIElements/Button';
import { Locations } from '@core/models/locations';

import './article-test.scss';


const ArticleTest: FunctionComponent = () => {
    const [
        {
            selectedWikiLessonModel,
            clearSelectedWikiLessonModel,
            selectedAvailableWikiCategoryModel,
            clearSelectedAvailableWikiArticleModel,
        },
        trainingRoomService,
        {
            t,
        },
        modalService,
    ] = useInstances(
        TrainingRoomStore,
        TrainingRoomService,
        I18NService,
        ModalService,
    );

    const { id } = useParams();
    const navigate = useNavigate();

    const b = block('article-test');

    const [answerNumber, setAnswerNumber] = useState<number | undefined>(undefined);

    const articleId = Number(id);

    const toArticleView = () => navigate(`${Locations.ARTICLE_VIEW}/${articleId}`);

    useEffect(
        () => {
            void trainingRoomService.initTestModel(articleId);

            return () => {
                clearSelectedWikiLessonModel();
                clearSelectedAvailableWikiArticleModel();
                const currentModalId = modalService.currentModalId;
                if (currentModalId) {
                    modalService.closeModalById(currentModalId);
                }
            };
        },
        [],
    );

    useEffect(() => {
        setAnswerNumber(undefined);
    }, [selectedWikiLessonModel?.currentAnswers]);

    if (!selectedWikiLessonModel) {
        return (
            <div className='spinner'>
                <Spinner/>
            </div>
        );
    }

    const {
        tests,
        countAllTests,
        currentQuestion,
        currentAnswers,
        completed,
    } = selectedWikiLessonModel;

    const { useCategoryTests, categoryId } = selectedAvailableWikiCategoryModel!;

    const handleAnswerClick = async (answerId: number) => {

        const saveRes = await trainingRoomService.saveResultTest(
            answerId,
            useCategoryTests ? categoryId : undefined,
            !useCategoryTests ? articleId : undefined,
        );

        if (saveRes) {
            await trainingRoomService.initTestModel(articleId);
        }
    };

    return (
        <div className={b()}>
            {tests.size !== 0 && (
                <div className = {b('frame')}>
                    <div className = {b('arrow')} onClick={toArticleView}>
                        <ArrowBackSvg />
                    </div>
                    <div className={b('question')}>
                        {currentQuestion}
                    </div>
                    <div className={b('answers')}>
                        {currentAnswers.map((answer) => (
                            <div className={b('answer')}
                                key={answer.answerId}
                                onClick={() => setAnswerNumber(answer.answerId)}
                            >
                                <RadioInput
                                    param={answerNumber}
                                    value={answer.answerId}
                                    label={answer.body as any}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <TrainingRoomFooter>
                <div className={b('count')}>{`${completed} / ${countAllTests}`}</div>
                <div className={b('progress')}>
                    <ProgressBar
                        completed={completed}
                        max={countAllTests}
                    />
                </div>
                <div className={b('button-next')}>
                    <Button
                        variant="7"
                        size="1"
                        text={t('Следующий', 'Next')}
                        preloaderColor="#fff"
                        onClick={() => handleAnswerClick(answerNumber!)}
                        disabled={!answerNumber}
                        padding='16px'
                    />
                </div>
            </TrainingRoomFooter>
        </div>
    );
};


export default observer(ArticleTest);
