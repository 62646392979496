import { toFactory } from 'react-ioc';
import TrainingRoomService from '@components/main/training-room-page/TrainingRoomService';
import UserApiService from '@api/user-api-service';
import WikiApiService from '@api/wiki-api-service';
import { TrainingRoomStore } from '@store/TrainingRoomStore';
import TestApiService from '@api/test-api-service';
import ModalService from '@core/services/ModalService';
import I18NService from '@services/I18NService';
import HistoryService from '@services/HistoryService';


const TrainingRoomServiceDI = (): any => [
    TrainingRoomService,
    toFactory([
        WikiApiService,
        UserApiService,
        TrainingRoomStore,
        TestApiService,
        ModalService,
        I18NService,
        HistoryService,
    ],
    (
        wikiApiService: WikiApiService,
        userApiService: UserApiService,
        trainingRoomStore: TrainingRoomStore,
        testApiService: TestApiService,
        modalService: ModalService,
        i18NService: I18NService,
        historyService: HistoryService,
    ) => new TrainingRoomService(
        userApiService,
        trainingRoomStore,
        wikiApiService,
        testApiService,
        modalService,
        i18NService,
        historyService,
    )),
];


export default TrainingRoomServiceDI;
