import React from 'react';
import { FC } from 'react';
import './ProgressBar.scss';

interface IProgressBarProps {
    completed: number;
    max: number;
}
const ProgressBar: FC<IProgressBarProps> = (props) => {
    const { completed, max } = props;

    const progressStep = (completed / max) * 100;
    return (
        <div className='progress-bar'>
            <div style={{ width: `${progressStep}%` }} className='progress-bar__filler'>
            </div>
        </div>
    );
};

export default ProgressBar;
