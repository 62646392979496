import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';
import { AvailableWikiBaseModel } from '@models/mobx-state-tree/training-room/availableWikiBase.model';
import { TestModel } from '@models/mobx-state-tree/training-room/test.model';
import {
    AvailableWikiArticleModel,
    IAvailableWikiArticleModel,
} from '@models/mobx-state-tree/training-room/availableWikiArticle.model';
import { StateStatusModel } from '@models/mobx-state-tree/training-room/stateStatus.model';

export const AvailableWikiCategoryModel = AvailableWikiBaseModel
    .props({
        description: t.string,
        categoryId: t.integer,
        countAllArticles: t.number,
        countStudiedArticles: t.number,
        articles: t.map(AvailableWikiArticleModel),
        icon: t.string,
        categoryTests: t.array(TestModel),
        useCategoryTests: t.optional(t.boolean, false),
        state: StateStatusModel,
    })
    .views((self) => ({
        get articlesView(): IAvailableWikiArticleModel[] {
            return [...self.articles.values()];
        },
        get articlesWithoutFeedback(): IAvailableWikiArticleModel[] {
            return [...self.articles.values()].filter(article => !article.feedbackAvailability) || [];
        },
    }))
    .actions((self) => ({
        nextLessonId(articleId: number): number | undefined {
            const articles = self.articlesWithoutFeedback;
            const indexCurrentArticle = articles.findIndex(el => el.articleId === articleId);
            for (let i = 0; i < articles.length; i++) {
                if (i > indexCurrentArticle) {
                    return articles[i].articleId;
                }
            }
            return articles[0]?.articleId;
        },
    }),
    );


export interface IAvailableWikiCategoryModel extends Instance<typeof AvailableWikiCategoryModel> {}
export interface IAvailableWikiCategoryModelSnapshotIn extends SnapshotIn<typeof AvailableWikiCategoryModel> {}
export interface IAvailableWikiCategoryModelSnapshotOut extends SnapshotOut<typeof AvailableWikiCategoryModel> {}
