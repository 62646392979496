import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';
import { IWikiTestModelModel, WikiTestModel } from '@models/mobx-state-tree/training-room/wikiTest.model';
import { StateStatusModel } from '@models/mobx-state-tree/training-room/stateStatus.model';
import { IAnswerModelModel } from '@models/mobx-state-tree/training-room/answer.model';


export const WikiLessonModel = t
    .model('WikiLessonModel', {
        id: t.identifier,
        countAllTests: t.optional(t.number, 0),
        tests: t.map(WikiTestModel),
        state: StateStatusModel,
        feedbackAvailability: t.boolean,
        questionNumber: t.optional(t.integer, 0),
    })
    .views((self) => ({
        get testsValues(): IWikiTestModelModel[] {
            return [...self.tests.values()];
        },

        get completed(): number {
            return self.countAllTests - self.tests.size;
        },
    }))
    .views((self) => ({
        get currentQuestion(): string {
            return self.testsValues[self.questionNumber]?.question;
        },

        get currentAnswers(): IAnswerModelModel[] {
            return self.testsValues[self.questionNumber]?.answers;
        },
    }))
    .actions((self) => ({
        nextButtonHandle(): void {
            self.questionNumber = self.questionNumber + 1 >= self.tests.size && self.tests.size !== 0
                ? 0
                : self.questionNumber + 1;
        },

        previousButtonHandle(): void {
            self.questionNumber = self.questionNumber === 0 && self.tests.size !== 0
                ? self.tests.size - 1
                : self.questionNumber - 1;
        },
    }));


export interface IWikiLessonModel extends Instance<typeof WikiLessonModel> {}
export interface IWikiLessonModelSnapshotIn extends SnapshotIn<typeof WikiLessonModel> {}
export interface IWikiLessonModelSnapshotOut extends SnapshotOut<typeof WikiLessonModel> {}
