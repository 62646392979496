import map from 'lodash/map';
import each from 'lodash/each';
import { v4 } from 'uuid';
import { applySnapshot } from 'mobx-state-tree';

import UserApiService from '@api/user-api-service';
import WikiApiService from '@api/wiki-api-service';
import { IAvailableWikiArticle, IAvailableWikiCategory, ITestAnswerResource } from '@api/wiki-api-service/model';
import {
    IAvailableWikiCategoryModelSnapshotIn,
} from '@models/mobx-state-tree/training-room/availableWikiCategory.model';
import { IAvailableWikiArticleModelSnapshotIn } from '@models/mobx-state-tree/training-room/availableWikiArticle.model';
import convertArrayToCollection from '@core/helpers/convertArrayToCollection';
import { TrainingRoomStore } from '@store/TrainingRoomStore';
import TestApiService from '@api/test-api-service';
import { ITestDetail } from '@api/test-api-service/models';
import { IWikiLessonModelSnapshotIn } from '@models/mobx-state-tree/training-room/wikiLesson.model';
import { IWikiTestModelSnapshotIn } from '@models/mobx-state-tree/training-room/wikiTest.model';
import { IAnswerModelSnapshotIn } from '@models/mobx-state-tree/training-room/answer.model';
import ModalService from '@core/services/ModalService';
import FeedbackChoice from '@components/main/training-room-page/modals/feedback-choice/feedback-choice';
import FeedbackSent from '@components/main/training-room-page/modals/feedback-sent/feedback-sent';
import I18NService from '@services/I18NService';
import { IStateStatusModelSnapshotIn } from '@models/mobx-state-tree/training-room/stateStatus.model';
import HistoryService from '@services/HistoryService';
import { Locations } from '@core/models/locations';
import { To } from 'history';
import { ConfirmModalAction, ConfirmModalType } from '@core/models/ModalWindow';
import isEmpty from 'lodash/isEmpty';

export enum TrainingRoomEventEnum {
    TRAININGROOM = 'training_room',
}

enum TrainingRoomEventActionEnum {
    START = 'start',
    STOP = 'stop',
}

interface ITextButton {
    [key: string]: string;
}

/**
 * Интервал в 3 минуты для события training room (фиксируется время прибывания оператора в тренировочной комнате)
 */
const trainingRoomInterval = 300000;


class TrainingRoomService {
    private _selectedAvailableWikiArticleModel: IAvailableWikiArticleModelSnapshotIn;

    private _timer: NodeJS.Timeout;

    private _textOfButton: string;

    private _article: IAvailableWikiArticle;

    private _category: IAvailableWikiCategory;

    private _keyOfButton: string;

    private get _pushHistory(): (to: To, state?: any) => void {
        return this._historyService.history.push;
    }

    constructor(
        private readonly _userApiService: UserApiService,
        private readonly _trainingRoomStore: TrainingRoomStore,
        private readonly _wikiApiService: WikiApiService,
        private readonly _testApiService: TestApiService,
        private readonly _modalService: ModalService,
        private readonly _i18NService: I18NService,
        private readonly _historyService: HistoryService,
    ) {
    }

    static createCategoryModelSnapshotIn(category: IAvailableWikiCategory): IAvailableWikiCategoryModelSnapshotIn {
        return {
            id: v4(),
            categoryId: category.id,
            countAllArticles: category.count_all_articles,
            countStudiedArticles: category.count_studied_articles,
            name: category.name,
            title: category.title,
            description: category.description,
            isOpenByProgress: category.is_open_by_progress,
            icon: category.icon,
            state: {
                id: category.state.id,
                name: category.state.name,
                code: category.state.code,
                color: category.state.color,
            },
            articles: convertArrayToCollection<IAvailableWikiArticleModelSnapshotIn>(
                map<IAvailableWikiArticle, IAvailableWikiArticleModelSnapshotIn>(
                    category.articles,
                    TrainingRoomService.createArticleModelSnapshotIn,
                ) || {}),
            categoryTests: category.tests,
            useCategoryTests: Boolean(category.tests?.length),
        };
    }

    static createArticleModelSnapshotIn(article: IAvailableWikiArticle): IAvailableWikiArticleModelSnapshotIn {
        return {
            id: v4(),
            articleId: article.id,
            categoryId: article.category?.id || null,
            name: article.name,
            title: article.title,
            text: article.text,
            isOpenByProgress: article.is_open_by_progress,
            description: article.description,
            state: {
                id: article.state.id,
                name: article.state.name,
                code: article.state.code,
                color: article.state.color,
            },
            tests: article.tests,
            feedbackAvailability: Boolean(article.wikiRating),
        };
    }

    static createAnswerSnapshotIn(data: ITestAnswerResource): IAnswerModelSnapshotIn {
        return {
            id: v4(),
            answerId: data.id,
            body: data.answer,
            isApplicantAnswer: data.is_applicant_answer,
            isCheckbox: data.is_checkbox,
        };
    }

    async fetchCategoriesWithArticles(): Promise<void> {
        try {
            const response = await this._wikiApiService.getAccessibleCategoriesWithArticles();

            const transformedCategories = convertArrayToCollection<IAvailableWikiCategoryModelSnapshotIn>(
                map<IAvailableWikiCategory, IAvailableWikiCategoryModelSnapshotIn>(
                    response,
                    TrainingRoomService.createCategoryModelSnapshotIn,
                )) || {};

            applySnapshot(
                this._trainingRoomStore.availableWikiCategories,
                transformedCategories,
            );

        } catch (error) {
            console.error(error);
        }
    }

    async initCategoryModel(categoryId: number): Promise<void> {
        try {
            const res = await this._wikiApiService.getCategoryById(categoryId);

            this._category = res;

            const categoryModelSnapshotIn = TrainingRoomService.createCategoryModelSnapshotIn(res);

            this._trainingRoomStore.setSelectedAvailableWikiCategoryModel(categoryModelSnapshotIn);
        } catch (error) {
            console.error(error);
        }
    }

    async initArticleModel(articleId: number): Promise<void> {
        try {
            const res = await this._wikiApiService.getArticleById(articleId);

            this._article = res;

            const articleModelSnapshotIn = TrainingRoomService.createArticleModelSnapshotIn(res);

            this._trainingRoomStore.setSelectedAvailableWikiArticleModel(articleModelSnapshotIn);
            this._selectedAvailableWikiArticleModel = articleModelSnapshotIn;
        } catch (error) {
            console.error(error);
        }
    }

    async initTestModel(articleId: number): Promise<void> {

        /**
         * Нижепреведенный код с const countAllTests необходим для корректной работы прогрессбара в тестах;
         */
        const countAllTests = this._trainingRoomStore.selectedWikiLessonModel?.countAllTests;

        try {

            await this.initArticleModel(articleId);
            const categoryId = this._selectedAvailableWikiArticleModel.categoryId;
            await this.initCategoryModel(categoryId!);

            const { selectedAvailableWikiCategoryModel } = this._trainingRoomStore;

            const { useCategoryTests } = selectedAvailableWikiCategoryModel!;

            const article = this._article;

            const category = this._category;

            const { id, code, color, name } = article.state;

            const state: IStateStatusModelSnapshotIn = {
                id,
                code,
                color,
                name,
            };

            const lessonModelSnapshotIn: IWikiLessonModelSnapshotIn = {
                id: v4(),
                tests: {},
                state,
                feedbackAvailability: Boolean(article.wikiRating),
                countAllTests,
            };

            if (useCategoryTests ? category.tests.length : article.tests.length) {
                each<ITestDetail>(
                    await Promise.all(
                        useCategoryTests ?
                            map(category.tests, (test) => this._testApiService.getTest(test.id, categoryId!, undefined)) :
                            map(article.tests, (test) => this._testApiService.getTest(test.id, undefined, articleId)),
                    ),
                    (res) => {
                        if (!lessonModelSnapshotIn.countAllTests) {
                            Object.assign(lessonModelSnapshotIn, { countAllTests: res.count_all_tests });
                        }

                        const id = v4();

                        const testSnapshotIn: IWikiTestModelSnapshotIn = {
                            id,
                            testId: res.test.id,
                            ordinalNumber: res.test.number,
                            question: res.test.content,
                            answers: map(res.test.answers, TrainingRoomService.createAnswerSnapshotIn),
                        };

                        Object.assign(lessonModelSnapshotIn.tests, { [id]: testSnapshotIn });
                    },
                );
            }

            this._trainingRoomStore.setSelectedWikiLessonModel(lessonModelSnapshotIn);
            await this._testProcessing(articleId);
        } catch {
            await this._modalService.showErrorNotificationModal(this._i18NService.t(
                'Не удалось загрузить урок. Попробуйте позже...',
                'The lesson could not be loaded. Try again later...',
            ));

            this._pushHistory(`${Locations.ARTICLE_VIEW}/${articleId}`);
        }
    }

    private async _testProcessing(articleId: number): Promise<void> {
        if (this._trainingRoomStore.selectedWikiLessonModel) {
            const {
                selectedWikiLessonModel: {
                    nextButtonHandle,
                    tests,
                    feedbackAvailability,
                    state: {
                        isTestPassed,
                    },
                },
                selectedAvailableWikiCategoryModel,
            } = this._trainingRoomStore;
            if (selectedAvailableWikiCategoryModel) {

                nextButtonHandle();

                if (!tests.size) {
                    if (!feedbackAvailability) {
                        this.formedTextOfButton();
                        const resultFeedbackSent = await this.showModalWithFeedback(articleId, this._textOfButton);
                        if (!resultFeedbackSent) {
                            return;
                        }
                    } else if (isTestPassed) {
                        await this._modalService.showConfirmModal(
                            this._i18NService.t(
                                'Вы уже прошли и оценили этот урок',
                                'You have already experienced and appreciated this lesson',
                            ),
                            ConfirmModalType.Yes,
                            10000,
                            ConfirmModalAction.Yes,
                        );
                    }
                    this.navigate(articleId);
                }
            }
        }
    }

    async showModalWithFeedback(articleId: number, textOfButton: string): Promise<boolean> {

        let resultFeedbackSent = false;

        try {
            const res = await this._modalService.showModal<any>(
                FeedbackChoice,
                {
                    testApiService: this._testApiService,
                    articleId,
                },
                true,
            );

            if (res) {
                resultFeedbackSent = await this._modalService.showModal<any>(
                    FeedbackSent,
                    {
                        textOfButton,
                    },
                    true,
                );
            }
        } catch (error) {
            await this._modalService.showErrorNotificationModal(error);
        }

        return resultFeedbackSent;
    }

    _saveTextOfButton = (key: string): string => {
        const { t } = this._i18NService;

        const textButton: ITextButton = {
            goToNextLesson: t('перейти к следующему уроку', 'go to next lesson'),
            goToTest: t('пройти тест', 'pass the test'),
            goToCategories: t('перейти к разделам', 'go to categories'),
        };

        this._setTextOfButton(key, textButton[key]);

        return textButton[key];
    };

    getKeyOfButton = (): string => {
        return this._keyOfButton;
    };

    /**
     * Функция отвечает за формирование текста кнопки на странице с отдельной статьей (article-view) и сохранение данных,
      неоходимых для формирования логики перехода по страницам
     */

    formedTextOfButton(): string {

        const { selectedAvailableWikiCategoryModel } = this._trainingRoomStore;

        const { feedbackAvailability, tests } = this._trainingRoomStore.selectedAvailableWikiArticleModel!;

        const { countStudiedArticles, countAllArticles, categoryTests } = selectedAvailableWikiCategoryModel!;

        const isArticleTestsExists = !isEmpty(tests);
        const isCategoryTestsExists = !isEmpty(categoryTests);

        if ((isCategoryTestsExists && countStudiedArticles === countAllArticles - 1 && !feedbackAvailability) ||
            isArticleTestsExists) {
            return this._saveTextOfButton('goToTest');
        }

        if (countStudiedArticles === countAllArticles || (countStudiedArticles === countAllArticles - 1 &&
            !feedbackAvailability)) {
            return this._saveTextOfButton('goToCategories');
        }

        return this._saveTextOfButton('goToNextLesson');

    }

    /**
     * Методы startTrainingRoomEvent, setIntervalTrainingRoomEvent, endTrainingRoomEvent используются
     * для фиксации времени проведенного оператором в тренировочной комнате;
     */

    async startTrainingRoomEvent() {
        try {
            const dataToSend = {
                event: TrainingRoomEventEnum.TRAININGROOM,
                action: TrainingRoomEventActionEnum.START,
            };
            await this._userApiService.sendUserEvent(dataToSend);
        } catch (error) {
            await this._modalService.showErrorNotificationModal(error);
        }
    }

    public _setTextOfButton(key: string, textOfButton: string) {
        this._textOfButton = textOfButton;
        this._keyOfButton = key;
    }

    public setIntervalTrainingRoomEvent() {
        this._timer = setInterval(() => {
            void this.startTrainingRoomEvent();
        }, trainingRoomInterval);
    }

    async endTrainingRoomEvent() {
        try {
            clearInterval(this._timer);
            const dataToSend = {
                event: TrainingRoomEventEnum.TRAININGROOM,
                action: TrainingRoomEventActionEnum.STOP,
            };
            await this._userApiService.sendUserEvent(dataToSend);
        } catch (error) {
            await this._modalService.showErrorNotificationModal(error);
        }
    }

    public navigate(articleId: number) {
        try {
            const { selectedAvailableWikiCategoryModel } = this._trainingRoomStore;
            const nextLessonId = selectedAvailableWikiCategoryModel?.nextLessonId(articleId);

            const path = (this._keyOfButton === 'goToNextLesson' || this._keyOfButton === 'goToTest') ?
                `${Locations.ARTICLE_VIEW}/${nextLessonId}` : `${Locations.CATEGORIES}`;

            this._pushHistory(path);

        } catch (e) {
            console.log(e);
        }
    }

    async showFeedbackAndGoToNextLesson(articleId: number) {

        const feedbackAvailability  = this._selectedAvailableWikiArticleModel.feedbackAvailability;

        if (!feedbackAvailability) {
            const resultFeedbackSent = await this.showModalWithFeedback(articleId, this._textOfButton);
            if (!resultFeedbackSent) {
                return;
            }
        }

        this.navigate(articleId);
    }

    async saveResultTest(answerId: number, categoryId: number | undefined, articleId: number | undefined): Promise<boolean> {
        try {
            return await this._testApiService.saveResult(answerId, categoryId, articleId);
        } catch {
            await this._modalService.showErrorNotificationModal(this._i18NService.t(
                'Не удалось сохранить результат вопроса',
                'Failed to keep the result of the question',
            ));

            return false;
        }
    }
}


export default TrainingRoomService;
