import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import { Instance } from 'mobx-state-tree';
import {
    ITrainingRoomStoreModel,
    TrainingRoomStoreModel,
} from '@models/mobx-state-tree/training-room/trainingRoomStore.model';


export type IEnvTrainingRoomStore = {
    I18NService: I18NService;
    rootStore: Store;
};


export class TrainingRoomStore {
    public static create(
        rootStore: Store,
        I18NService: I18NService,
    ): ITrainingRoomStoreModel {
        const env: IEnvTrainingRoomStore = {
            rootStore,
            I18NService,
        };

        return TrainingRoomStoreModel.create({}, env);
    }
}


export interface TrainingRoomStore extends Instance<typeof TrainingRoomStoreModel> {}
