import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';


export const AnswerModel = t
    .model('AnswerModel', {
        id: t.identifier,
        answerId: t.integer,
        body: t.string,
        isApplicantAnswer: t.boolean,
        isCheckbox: t.boolean,
    });


export interface IAnswerModelModel extends Instance<typeof AnswerModel> {}
export interface IAnswerModelSnapshotIn extends SnapshotIn<typeof AnswerModel> {}
export interface IAnswerModelSnapshotOut extends SnapshotOut<typeof AnswerModel> {}
