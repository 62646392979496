import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';


export const StateStatusModel = t
    .model('StateStatusModel', {
        id: t.number,
        name: t.string,
        code: t.number,
        color: t.maybeNull(t.string),
    })
    .views((self) => ({
        get isTestStarted(): boolean {
            return self.code === 1 || self.code === 5;
        },

        get isTestPassed(): boolean {
            return self.code === 2 || self.code === 7;
        },

        get isTestIsNotPassed(): boolean {
            return self.code === 3 || self.code === 8;
        },

        get isTestPending(): boolean {
            return self.code === 4;
        },

        get isTestIsNotAvailable(): boolean {
            return self.code === 6;
        },
    }));


export interface IStateStatusModel extends Instance<typeof StateStatusModel> {}
export interface IStateStatusModelSnapshotIn extends SnapshotIn<typeof StateStatusModel> {}
export interface IStateStatusModelSnapshotOut extends SnapshotOut<typeof StateStatusModel> {}
