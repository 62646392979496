import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import { TrainingRoomStore } from '@store/TrainingRoomStore';


const TrainingRoomStoreDI = (): any => [
    TrainingRoomStore,
    toFactory(
        [
            Store,
            I18NService,
        ],
        (
            rootStore: Store,
            I18NService: I18NService,
        ) => TrainingRoomStore.create(
            rootStore,
            I18NService,
        ),
    ),
];


export default TrainingRoomStoreDI;
