import React, { FunctionComponent, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useInstances } from 'react-ioc';

import TrainingRoomService from '@components/main/training-room-page/TrainingRoomService';

import './training-room-page.scss';

const TrainingRoomPage: FunctionComponent = () => {

    const [
        trainingRoomService,
    ] = useInstances(
        TrainingRoomService,
    );

    useEffect(() => {
        void trainingRoomService.startTrainingRoomEvent();
        void trainingRoomService.setIntervalTrainingRoomEvent();
        return () => {
            void trainingRoomService.endTrainingRoomEvent();
        };
    }, []);

    return (
        <div className='training-room-page'>
            <Outlet />
        </div>
    );
};


export default TrainingRoomPage;
