import React, { FunctionComponent } from 'react';

const ArrowBackSvg: FunctionComponent = (): JSX.Element => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7913 7.00508H3.62125L8.50125 2.12508C8.89125 1.73508 8.89125 1.09508 8.50125 0.705083C8.31442 0.517831 8.06077 0.412598 7.79625 0.412598C7.53173 0.412598 7.27808 0.517831 7.09125 0.705083L0.50125 7.29508C0.11125 7.68508 0.11125 8.31508 0.50125 8.70508L7.09125 15.2951C7.48125 15.6851 8.11125 15.6851 8.50125 15.2951C8.89125 14.9051 8.89125 14.2751 8.50125 13.8851L3.62125 9.00508H14.7913C15.3413 9.00508 15.7913 8.55508 15.7913 8.00508C15.7913 7.45508 15.3413 7.00508 14.7913 7.00508Z" fill="#B8C1CC"/>
    </svg>
);

export default ArrowBackSvg;