import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { block } from 'bem-cn';

import useQueriesHook from '@components/hooks/useQueriesHook';

import TrainingRoomService from '@components/main/training-room-page/TrainingRoomService';
import I18NService from '@services/I18NService';
import { TrainingRoomStore } from '@store/TrainingRoomStore';
import { Locations } from '@core/models/locations';
import BottomMobileTabs, { BottomMobileTabsPageEnum } from '../../bottom-mobile-menu/BottomMobileTabs';
import CategoryCard from '@components/main/training-room-page/category-card/category-card';

import './categories.scss';

const Categories: FunctionComponent = () => {
    const [
        trainingRoomService,
        {
            t,
        },
        {
            categories,
            clearCategories,
            clearSelectedAvailableWikiCategoryModel,
        },
    ] = useInstances(
        TrainingRoomService,
        I18NService,
        TrainingRoomStore,
    );

    const b = block('categories');

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    const { paramsValues } = useQueriesHook(['categoriesScrollY']);

    const { categoriesScrollY } = paramsValues;

    const getCategories = async () => {
        await trainingRoomService.fetchCategoriesWithArticles();
        if (categoriesScrollY) window.scrollTo(0, Number(categoriesScrollY));
    };

    useEffect(
        () => {
            void getCategories()
                .finally(() => setIsLoading(false));
            return () => {
                clearCategories();
                clearSelectedAvailableWikiCategoryModel();
            };
        },
        [],
    );

    const toCategoryView = (categoryId: number) => {
        const categoriesScrollY = Math.floor(window.scrollY);
        navigate(`${Locations.CATEGORY_VIEW}/${categoryId}?categoriesScrollY=${categoriesScrollY}`);
    };

    const text = isLoading ? t('Ожидайте. Идет загрузка категорий.', 'Wait. Categories are loading.') :
        t('Нет категорий статей.', 'No article categories.');

    return (
        <>
            <div className={b()}>
                <div className={b('page-title')}>
                    {t('Тренировочный зал', 'Training Room')}
                </div>
                <div className={b('catalog')}>
                    {categories.length > 0 && (
                        categories.map((category) => <CategoryCard
                            onClick={() => toCategoryView(category.categoryId)}
                            categoryInfo={category}
                            key={category.id} />,
                        )
                    )}
                    {!categories.length && (
                        <div className="no-content">
                            {text}
                        </div>
                    )}
                </div>
            </div>
            <BottomMobileTabs page={BottomMobileTabsPageEnum.TRAINING_ROOM} />
        </>
    );
};


export default observer(Categories);
