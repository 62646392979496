import React from 'react';

const CheckMarkSvg = (): JSX.Element => (
    <svg
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="1"
            y="1"
            width="86"
            height="86"
            rx="43"
            fill="white"
            stroke="#90CAF9"
            strokeWidth="2"
        />
        <path d="M28.7194 44.6138C27.9011 43.7956 26.5745 43.7956 25.7563 44.6138C24.938 45.4321 24.938 46.7587 25.7563 47.5769L36.2325 58.0531C37.0507 58.8714 38.3773 58.8714 39.1956 58.0531L62.2432 35.0055C63.0614 34.1873 63.0614 32.8606 62.2432 32.0424C61.4249 31.2242 60.0983 31.2242 59.2801 32.0424L37.714 53.6085L28.7194 44.6138Z"
            fill="#0060FF"
        />
    </svg>
);

export default CheckMarkSvg;