import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';

import {
    AvailableWikiCategoryModel,
    IAvailableWikiCategoryModel, IAvailableWikiCategoryModelSnapshotIn,
} from '@models/mobx-state-tree/training-room/availableWikiCategory.model';
import {
    AvailableWikiArticleModel,
    IAvailableWikiArticleModelSnapshotIn,
} from './availableWikiArticle.model';
import { IWikiLessonModelSnapshotIn, WikiLessonModel } from '@models/mobx-state-tree/training-room/wikiLesson.model';


export const TrainingRoomStoreModel = t
    .model('TrainingRoomStoreModel', {
        availableWikiCategories: t.map(AvailableWikiCategoryModel), // Все категории (разделы);
        selectedAvailableWikiCategoryModel: t.maybeNull(AvailableWikiCategoryModel), // Отдельная категория (раздел);
        selectedAvailableWikiArticleModel: t.maybeNull(AvailableWikiArticleModel), // Отдельная статья (урок);
        selectedWikiLessonModel: t.maybeNull(WikiLessonModel), // Тесты (вопросы)
    })
    .actions((self) => ({
        setSelectedAvailableWikiCategoryModel(data: IAvailableWikiCategoryModelSnapshotIn): void {
            self.selectedAvailableWikiCategoryModel = AvailableWikiCategoryModel.create(data);
        },

        clearSelectedAvailableWikiCategoryModel(): void {
            self.selectedAvailableWikiCategoryModel = null;
        },

        setSelectedAvailableWikiArticleModel(data: IAvailableWikiArticleModelSnapshotIn): void {
            self.selectedAvailableWikiArticleModel = AvailableWikiArticleModel.create(data);
        },

        clearSelectedAvailableWikiArticleModel(): void {
            self.selectedAvailableWikiArticleModel = null;
        },

        clearCategories(): void {
            self.availableWikiCategories.clear();
        },

        setSelectedWikiLessonModel(data: IWikiLessonModelSnapshotIn): void {
            self.selectedWikiLessonModel = WikiLessonModel.create(data);
        },

        clearSelectedWikiLessonModel(): void {
            self.selectedWikiLessonModel = null;
        },
    }))
    .views((self) => ({
        get categories(): IAvailableWikiCategoryModel[] {
            return [...self.availableWikiCategories.values()];
        },
    }));


export interface ITrainingRoomStoreModel extends Instance<typeof TrainingRoomStoreModel> {}
export interface ITrainingRoomStoreModelSnapshotIn extends SnapshotIn<typeof TrainingRoomStoreModel> {}
export interface ITrainingRoomStoreModelSnapshotOut extends SnapshotOut<typeof TrainingRoomStoreModel> {}
