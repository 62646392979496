import { provider } from 'react-ioc';

import TrainingRoomServiceDI from '@app/servicesDI/TrainingRoomServiceDI';
import TrainingRoomStoreDI from '@app/servicesDI/TrainingRoomStoreDI';
import WikiApiService from '@api/wiki-api-service';
import TrainingRoomPage from '@components/main/training-room-page/training-room-page';
import TestApiService from '@api/test-api-service';

export default provider(
    WikiApiService,
    TestApiService,
    TrainingRoomStoreDI(),
    TrainingRoomServiceDI(),
)(TrainingRoomPage);
