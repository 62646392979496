import React, { FunctionComponent, useEffect } from 'react';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { block } from 'bem-cn';

import useQueriesHook from '@components/hooks/useQueriesHook';

import TrainingRoomService from '@components/main/training-room-page/TrainingRoomService';
import { Locations } from '@core/models/locations';
import TrainingRoomFooter from '@components/main/training-room-page/training-room-footer/training-room-footer';
import { TrainingRoomStore } from '@/app/store/TrainingRoomStore';
import DivWithDangerouslySetInnerHTML from '@/app/ui-elements/DivWithDangerouslySetInnerHTML';
import ModalService from '@core/services/ModalService';
import Button from '@/app/ui-elements/Button';
import Spinner from '@/app/ui-elements/Spinner';

import './article-view.scss';
import ArrowBackGraySvg from '@/app/components/resources/SVG/ArrowBackGraySvg';


const ArticleView: FunctionComponent = () => {
    const [
        {
            selectedAvailableWikiArticleModel,
            selectedAvailableWikiCategoryModel,
        },
        trainingRoomService,
        modalService,
    ] = useInstances(
        TrainingRoomStore,
        TrainingRoomService,
        ModalService,
    );

    const navigate = useNavigate();

    const b = block('article-view');

    const { paramsValues, id } = useQueriesHook(['categoriesScrollY', 'articlesScrollY']);

    const articleId = Number(id);

    const categoryId =  selectedAvailableWikiCategoryModel?.categoryId || selectedAvailableWikiArticleModel?.categoryId;

    const { categoriesScrollY, articlesScrollY } = paramsValues;

    useEffect(
        () => {

            void trainingRoomService.initArticleModel(articleId);

            return () => {
                const currentModalId = modalService.currentModalId;
                if (currentModalId) {
                    modalService.closeModalById(currentModalId);
                }
            };
        },
        [id],
    );

    useEffect(() => {
        if (categoryId) {
            void trainingRoomService.initCategoryModel(categoryId);
        }
    }, [categoryId, id]);

    if (!selectedAvailableWikiArticleModel) {
        return (
            <div className='spinner'>
                <Spinner/>
            </div>
        );
    }

    const {
        title,
        text,
    } = selectedAvailableWikiArticleModel!;

    const toBack = () => {
        navigate(`${Locations.CATEGORY_VIEW}/${categoryId}?categoriesScrollY=${categoriesScrollY}&articlesScrollY=${articlesScrollY}`);
    };

    const textButton = selectedAvailableWikiCategoryModel && trainingRoomService.formedTextOfButton();

    const toTestOrAnotherLesson = () => {

        if (trainingRoomService.getKeyOfButton() === 'goToTest') {
            return navigate(`${Locations.ARTICLE_TEST}/${articleId}`, { replace: true });
        }
        void trainingRoomService.showFeedbackAndGoToNextLesson(articleId);
    };

    return (
        <>
            <div className={b()}>
                <div onClick={toBack} className={b('arrow-svg')}>
                    <ArrowBackGraySvg />
                </div>
                <div className={b('lesson-title')}>
                    {title}
                </div>
                <DivWithDangerouslySetInnerHTML
                    className={b('content')}
                    html={text}/>
            </div>
            <TrainingRoomFooter>
                <div className={b('footer')}>
                    <Button
                        onClick={toTestOrAnotherLesson}
                        text={textButton}
                        variant={7}
                        size={'1'}
                        padding={'16px'}
                    />
                </div>
            </TrainingRoomFooter>
        </>
    );
};


export default observer(ArticleView);
