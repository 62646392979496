import ApiBase from '@core/api';
import { getEnvWikiGetArticleByIDEndpoint, getEnvWikiGetCategoryByIDEndpoint, getEnvWikiGetDataEndpoint } from '@/env/requestEndpoints';
import get from 'lodash/get';
import { IAvailableWikiArticle, IAvailableWikiCategory } from '@api/wiki-api-service/model';
import { Inject } from 'react-ioc';


interface IWithResponse<T = any> {
    status: 'success' | 'error' | 'fail';
    message?: string;
    data: T;
}


class WikiApiService {
    @Inject apiBase: ApiBase;

    async getAccessibleCategoriesWithArticles(): Promise<IAvailableWikiCategory[]> {
        const response = await this.apiBase.get<IWithResponse<IAvailableWikiCategory[]>>(getEnvWikiGetDataEndpoint());

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getCategoryById(categoryId: number): Promise<IAvailableWikiCategory> {
        const response = await this.apiBase.get<IWithResponse<IAvailableWikiCategory>>(
            getEnvWikiGetCategoryByIDEndpoint(),
            {
                id: categoryId,
            },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getArticleById(articleId: number): Promise<IAvailableWikiArticle> {
        const response = await this.apiBase.get<IWithResponse<IAvailableWikiArticle>>(
            getEnvWikiGetArticleByIDEndpoint(),
            {
                id: articleId,
            },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data as IAvailableWikiArticle;
    }
}


export default WikiApiService;
