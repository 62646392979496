import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';

export const TestModel = t
    .model('WikiTestModel', {
        id: t.integer,
        content: t.string,
        sort: t.integer,
    });

export interface ITestModel extends Instance<typeof TestModel> {}
export interface ITestModelSnapshotIn extends SnapshotIn<typeof TestModel> {}
export interface ITestModelSnapshotOut extends SnapshotOut<typeof TestModel> {}