import { computed } from 'mobx';

import I18NService from '@services/I18NService';
import { Locations } from '@core/models/locations';
import { customI18NTFunction } from '@services/I18NService';
import getShowInDevItems from '@/env/getShowInDevItems';
import getIsOldFormHybridMode from '@/env/getIsOldFormHybridMode';

import CallIcon from '@images/sidebar/CombinedShape.svg';
import PlusIconSvg from '@images/sidebar/PlusIconSvg.svg';
import MagnifyingGlassSvg from '@images/sidebar/MagnifyingGlassSvg.svg';
import RecallsIcon from '@images/sidebar/Recalls.svg';
import TrainingRoomIcon from '@images/sidebar/TrainingRoom.svg';
import CustomerServiceIcon from '@images/sidebar/CustomerService.svg';

import isFeatureFlagRecallsListEnabled from '../helpers/isFeatureFlagRecallsListEnabled';
import { Store } from '@store/store';


export interface IDataLink {
    readonly icon: string;
    readonly textLink: string;
    readonly toRoute?: Locations;
    readonly toURL?: string;
    readonly inNewWindow?: boolean;
    readonly onlyCustomerService?: boolean;
    readonly hideOnCustomerService?: boolean;
}


class SidebarService {
    private readonly _showInDevItems: boolean;

    private readonly _isOldFormHybridMode: boolean;

    private get _t(): customI18NTFunction {
        return this._I18NService.t;
    }

    private get _dataLink(): IDataLink[] {
        const DataLinkArray = [
            {
                icon: CallIcon,
                textLink: this._t('ЗВОНКИ', 'CALLS'),
                toRoute: Locations.MAIN,
                hideOnCustomerService: true,
            },
            {
                icon: RecallsIcon,
                textLink: this._t('ПЕРЕЗВОНЫ', 'RECALLS'),
                toRoute: Locations.RECALLS,
            },
            // {
            //     icon: ActivityIcon,
            //     textLink: this._t('АКТИВНОСТЬ', 'ACTIVITY'),
            //     toRoute: Locations.BI_REPORT,
            // },
            // {
            //     icon: BillingListIcon,
            //     textLink: this._t('РАСЧЕТНЫЙ ЛИСТ', 'BILLING LIST'),
            //     toRoute: Locations.BILLING_LIST,
            // },
            {
                icon: TrainingRoomIcon,
                textLink: this._t('КОМНАТА ДЛЯ ТРЕНИРОВОК', 'TRAINING ROOM'),
                toRoute: Locations.CATEGORIES,
            },
            {
                icon: this._store.currentUser.isVerificator ?  MagnifyingGlassSvg : PlusIconSvg,
                textLink: this._store.currentUser.buttonUi,
                toRoute: this._store.currentUser.isVerificator ? Locations.FIND_ORDER : Locations.ORDER_CREATION,
            },
            {
                icon: CustomerServiceIcon,
                textLink: this._t('КЛИЕНТСКИЙ СЕРВИС', 'CUSTOMER SERVICE'),
                toRoute: Locations.CUSTOMER_SERVICE,
                onlyCustomerService: true,
            },
        ];

        if (isFeatureFlagRecallsListEnabled()) {
            DataLinkArray.splice(1, 1,
                {
                    icon: RecallsIcon,
                    textLink: this._t('ПЕРЕЗВОНЫ', 'RECALLS'),
                    toRoute: Locations.RECALLS,
                });
        }

        return DataLinkArray;
    }

    private get _dataLinkHybridMode(): IDataLink[] {
        const DataLinkArray = [
            {
                icon: CallIcon,
                textLink: this._t('ЗВОНКИ', 'CALLS'),
                toRoute: Locations.MAIN },
            {
                icon: RecallsIcon,
                textLink: this._t('ПЕРЕЗВОНЫ', 'RECALLS'),
                toRoute: Locations.RECALLS,
            },
            // {
            //     icon: ActivityIcon,
            //     textLink: this._t('АКТИВНОСТЬ', 'ACTIVITY'),
            //     toRoute: Locations.BI_REPORT,
            // },
            // {
            //     icon: BillingListIcon,
            //     textLink: this._t('РАСЧЕТНЫЙ ЛИСТ', 'BILLING LIST'),
            //     toRoute: Locations.BILLING_LIST,
            // },
            {
                icon: TrainingRoomIcon,
                textLink: this._t('КОМНАТА ДЛЯ ТРЕНИРОВОК', 'TRAINING ROOM'),
                toRoute: Locations.TRAINING_ROOM,
            },
            {
                icon: this._store.currentUser.isVerificator ?  MagnifyingGlassSvg : PlusIconSvg,
                textLink: this._store.currentUser.buttonUi,
                toRoute: this._store.currentUser.isVerificator ? Locations.FIND_ORDER : Locations.ORDER_CREATION,
            },
            {
                icon: CustomerServiceIcon,
                textLink: this._t('КЛИЕНТСКИЙ СЕРВИС', 'CUSTOMER SERVICE'),
                toRoute: Locations.CUSTOMER_SERVICE,
                onlyCustomerService: true,
            },
        ];

        return DataLinkArray;
    }

    constructor(private readonly _I18NService: I18NService, private readonly _store: Store) {
        this._showInDevItems = getShowInDevItems();
        this._isOldFormHybridMode = getIsOldFormHybridMode();
    }

    @computed
    get dataLink(): IDataLink[] {
        if (this._isOldFormHybridMode) {
            return this._dataLinkHybridMode;
        }

        return this._dataLink;
    }
}


export default SidebarService;
