import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';
import { AvailableWikiBaseModel } from '@models/mobx-state-tree/training-room/availableWikiBase.model';
import { StateStatusModel } from '@models/mobx-state-tree/training-room/stateStatus.model';
import { TestModel } from '@models/mobx-state-tree/training-room/test.model';


export const AvailableWikiArticleModel = AvailableWikiBaseModel
    .props({
        articleId: t.number,
        categoryId: t.maybeNull(t.number),
        text: t.string,
        description: t.maybeNull(t.string),
        state: StateStatusModel,
        tests: t.array(TestModel),
        feedbackAvailability: t.boolean,
    });


export interface IAvailableWikiArticleModel extends Instance<typeof AvailableWikiArticleModel> {}
export interface IAvailableWikiArticleModelSnapshotIn extends SnapshotIn<typeof AvailableWikiArticleModel> {}
export interface IAvailableWikiArticleModelSnapshotOut extends SnapshotOut<typeof AvailableWikiArticleModel> {}
