import { IAvailableWikiCategoryModel } from '@models/mobx-state-tree/training-room/availableWikiCategory.model';
import { block } from 'bem-cn';
import React, { FunctionComponent } from 'react';
import './categories-card.scss';

interface ICategoryCardProps {
    categoryInfo: IAvailableWikiCategoryModel;
    onClick: () => void;
}

const CategoryCard: FunctionComponent<ICategoryCardProps> = ({
    categoryInfo: {
        title,
        description,
        countAllArticles,
        countStudiedArticles,
        isOpenByProgress,
        icon,
    },
    onClick,
}) => {
    const b = block('category-card');

    return (
        <div onClick={onClick} className={b({ disabled: !isOpenByProgress })}>
            <div className={b('photo')}>
                <img src={icon} alt="logo"/>
            </div>
            <div className={b('info')}>
                <div className={b('card-title')}>{title}</div>
                <div className={b('card-description')}>{description}</div>
                <div className={b('number')}>{countStudiedArticles} / {countAllArticles}</div>
            </div>
        </div>

    );
};

export default CategoryCard;
