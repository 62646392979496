import React, { FunctionComponent } from 'react';

import './training-room-footer.scss';

interface ITrainingRoomFooter {
    children?: React.ReactNode;
}

const TrainingRoomFooter: FunctionComponent<ITrainingRoomFooter> = (props) => {
    return (
        <div className='training-room-footer'>
            {props.children}
        </div>
    );
};

export default TrainingRoomFooter;